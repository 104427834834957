.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  transform-origin: right;
}

.item-down,
.item-up {
  transition: opacity 0.3s linear, transform 0.3s linear !important;
}

// .item-down-exit-active,
// .item-down-enter {
//   transform: translateY(-50px);
// }

.item-down-exit,
.item-up-exit {
  z-index: 999;
  opacity: 1;
}

.item-up-enter {
  transform: translateY(20px);
  opacity: 0;
}

.item-down-enter {
  transform: translateY(-20px);
  opacity: 0;
}

.item-up-exit-active {
  // transform: translateY(20px);
  opacity: 0;
}

.item-down-exit-active {
  // transform: translateY(-20px);
  opacity: 0;
  // transition: all 0.3s linear !important;
}

.item-up-enter-active,
.item-down-enter-active {
  transform: translateY(-1px);
  opacity: 1;
  // transition: all 0.3s linear !important;
}
