// COLORS
$CBlack: #000;
$CWhite: #fff;
$CBlue: #0d93e2;
$CGreen: #1ecd79;
$COrange: #ff8c39;
$CPurple: #8665e3;
$CBlueLight: #5ac8fa;
$CRed: #ff4439;
$CLightGrey: #a0aec0;
$CGrey-100: #f5f7fa;
$CGrey-200: #edf2f7;
$CGrey-300: #e2e8f0;
$CGrey-500: #a0aec0;
$CGrey-700: #4a5568;
$CTransparent: rgba(0, 0, 0, 0);

// FONTS
$FFamily: 'Inter';

$FSizeH1: 64px;
$FLHH1: 89.6px;

$FSizeH1m: 48px;
$FLHH1m: 67.2px;

$FSizeH2: 38px;
$FLHH2: 53.2px;

$FSizeH3: 30px;
$FLHH3: 42px;

$FSizeH4: 24px;
$FLHH4: 33.6px;

$FSizeH5: 18px;
$FLHH5: 28.8px;

$FSizeTXL: $FSizeH4;
$FLHTXL: $FLHH4;

$FSizeTB: $FSizeH5;
$FLHTB: $FLHH5;

$FSizeTN: 16px;
$FLHTN: 24px;

$FSizeTS: 14px;
$FLHTS: 21px;

$FSizeTXS: 12px;
$FLHTXS: 16.8px;

$FWSB: 600;
$FWM: 500;
$FWN: normal;

$FSpacingB: -0.3px;
$FSpacingN: -0.2px;
$FSpacingS: -0.1px;

// Button
$BtnBgPrimary: $CBlue;
$BtnCPrimary: $CWhite;

$BtnBgSuccess: $CGreen;
$BtnCSuccess: $CWhite;

// media
$media-big: 1440px;
$media-dp: 1282px;
$media-dpsm: 1282px;

$mediaBigScreen: 'min-width: 1440px';
$mediaDesktop: 'min-width: 1282px';
$mediaDesktopSmall: 'max-width: 1281px';
$mediaTabletBig: 'max-width: 992px';
$mediaTablet: 'max-width: 768px';
$mediaMobile: 'max-width: 600px';
$mediaMobileSmall: 'max-width: 480px';
$mediaXS: 'max-width: 374px';
$mediaPortrait: 'orientation: portrait';
$mediaRetina: 'min-resolution: 2dppx';
$mediaDesktopNormalAndLaptop: '(min-width: 769px) and (max-width: 1170px)';
