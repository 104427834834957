@import '~assets/scss/variables';

@mixin h-1 {
  @include h-1m;

  @media ($mediaDesktop) {
    font-size: $FSizeH1;
    line-height: $FLHH1;
  }

  @media ($mediaTabletBig) {
    @include h-2;
  }

  @media ($mediaTablet) {
    @include h-4;
  }

  @media ($mediaXS) {
    @include h-5;

    font-size: 22px;
  }
}

@mixin h-1m {
  font-weight: $FWSB;
  font-size: $FSizeH1m;
  line-height: $FLHH1m;
  letter-spacing: $FSpacingB;
}

@mixin h-2 {
  font-weight: $FWSB;
  font-size: $FSizeH2;
  line-height: $FLHH2;
  letter-spacing: $FSpacingB;

  @media ($mediaDesktopSmall) {
    @include h-3;
  }

  @media ($mediaTablet) {
    @include h-4;
  }
}

@mixin h-3 {
  font-weight: $FWSB;
  font-size: $FSizeH3;
  line-height: $FLHH3;
  letter-spacing: $FSpacingB;
}

@mixin h-4 {
  font-weight: $FWSB;
  font-size: $FSizeH4;
  line-height: $FLHH4;
  letter-spacing: $FSpacingN;
}

@mixin h-5 {
  font-weight: $FWSB;
  font-size: $FSizeH5;
  font-size: $FSizeH5;
  letter-spacing: $FSpacingN;
}

@mixin text-xl {
  font-weight: normal;
  font-size: $FSizeTXL;
  line-height: $FLHTXL;
  letter-spacing: $FSpacingN;
}

@mixin text-big {
  font-weight: normal;
  font-size: $FSizeTB;
  line-height: $FLHTB;
  letter-spacing: $FSpacingN;
}

@mixin text-medium {
  font-size: $FSizeTB;
  line-height: $FLHTB;
  letter-spacing: $FSpacingN;

  @media ($mediaTablet) {
    @include text-normal;
  }
}

@mixin text-normal {
  font-weight: normal;
  font-size: $FSizeTN;
  line-height: $FLHTN;
  letter-spacing: $FSpacingN;
}

@mixin text-small {
  font-weight: normal;
  font-size: $FSizeTS;
  line-height: $FLHTS;
  letter-spacing: $FSpacingN;
}

@mixin text-xs {
  font-weight: normal;
  font-size: $FSizeTXS;
  line-height: $FLHTXS;
  letter-spacing: $FSpacingS;
}

@mixin text-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.text {
  &-fwsb {
    font-weight: $FWSB !important;
  }

  &-fwm {
    font-weight: $FWM !important;
  }

  &-fwn {
    font-weight: $FWN !important;
  }
}

h1,
.h-1 {
  @include h-1;
}

h2,
.h-2 {
  @include h-2;
}

h3,
.h-3 {
  @include h-3;
}

h4,
.h-4 {
  @include h-4;
}

h5,
.h-5 {
  @include h-5;
}

p {
  @include text-medium;

  @media (max-width: 768px) {
    @include text-normal;
  }
}

.text-body,
.text-normal {
  @include text-normal;
}

.text-inherit {
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.text-xl {
  @include text-xl;
}

.text-big {
  @include text-big;
}

.text-medium {
  @include text-medium;
}

.text-small {
  @include text-small;
}

.text-xs {
  @include text-xs;
}
